import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  Nav,
  Navbar,
  NavbarBrand,
  Row,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import { useHistory } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';
import { endUserSession } from '../../actions';
import LocationSelector from '../LocationSelector/LocationSelector';
import I18n, {
  getLanguageLocale,
  updateLanguageLocale,
} from '../../../i18n/utils';
import { claimsAdminRole } from '../../utils/AuthUtils';
import './NavHeader.scss';
import { routeToNewApp } from '../../utils/redirectUtil';

const NavHeader = ({ buildLocationChangeRedirectUrl }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const userToken = useSelector((state) => state.auth.userToken);

  const dispatch = useDispatch();
  const history = useHistory();
  // const currentURL = useLocation();

  const selectedLocation = useSelector(
    (state) => state.location.selectedLocation,
  );

  const [avatarPopupOpen, setAvatarPopoverOpen] = useState(false);
  const [languageLocale, setLanguageLocale] = useState(getLanguageLocale());
  const [loading, setLoading] = useState(false);

  const toggleLanguageLocale = () => {
    const language = languageLocale === 'en' ? 'es' : 'en';
    setLanguageLocale(language);
    setLoading(true);
    updateLanguageLocale(language);
  };

  const toggleAvatarPopover = () => {
    setAvatarPopoverOpen(!avatarPopupOpen);
  };

  const signOut = () => {
    dispatch(endUserSession(false));
    localStorage.clear();
    // Clear app session
    oktaAuth.tokenManager.clear();
    // Send to dev or prod cfahome
    window.location.href =
      process.env.NODE_ENV === 'production'
        ? 'https://portal.cfahome.com'
        : 'https://cfahome.oktapreview.com';
  };

  const closePopover = () => {
    // Simple solution to close Popover containing Location Selector
    document.body.click();
  };

  const navigateToAdmin = () => {
    routeToNewApp(`/admin/landing`);
  };

  const navigateToLanding = () =>
    routeToNewApp(`/${selectedLocation.locationNumber}/landing`);

  return (
    <Navbar
      className="navbar navbar-expand-sm justify-content-end"
      data-testid="nav-header-expand-sm-justify-content-end-navbar"
    >
      <NavbarBrand
        className="nav-app-logo-container"
        data-testid="nav-header-nav-app-logo-container-navbar-brand"
        onClick={navigateToLanding}
      >
        <img
          id="app-icon"
          src={require('../../assets/icons/cfa-logo.svg').default}
          width="35"
          height="35"
          className="nav-app-logo"
          data-testid="nav-header-nav-app-logo"
          alt="CFA Logo"
        />
        <UncontrolledTooltip
          data-testid="nav-header-page-title-uncontrolled-tooltip"
          placement="bottom-end"
          target="app-icon"
        >
          {I18n.t('APP_LANDING_PAGE_TITLE')}
        </UncontrolledTooltip>
      </NavbarBrand>
      <NavbarBrand
        className="admin-navbar-brand"
        data-testid="nav-header-admin-navbar-brand"
      >
        <h3
          className="nav-title"
          data-testid="nav-header-nav-title"
          alt="Food Safety Home"
          onClick={navigateToLanding}
        >
          {I18n.t('APP_SHORT_TITLE')}
        </h3>
      </NavbarBrand>

      {/* {currentURL?.pathname.includes('/admin') === false && */}
      {/*  <NavItem className='navbar__item navbar__item--first'> */}
      {/*    <NavLink */}
      {/*      className='navbar__link' */}
      {/*      activeClassName='navbar__link-selected' */}
      {/*      to={`/${selectedLocation?.locationNumber}/report-dashboard`} */}
      {/*      isActive={(match, location) => location?.pathname.includes('report-dashboard')} */}
      {/*    > */}
      {/*      <span> */}
      {/*        Reports */}
      {/*      </span> */}
      {/*    </NavLink> */}
      {/*  </NavItem>} */}
      <span className="ml-auto" data-testid="nav-header-ml-auto" />
      {userToken && authState.isAuthenticated && (
        <Nav className="flex-grow-0" data-testid="nav-header-flex-grow-0-nav">
          <LocationSelector
            data-testid="nav-header-location-selector"
            closePopover={closePopover}
            buildLocationChangeRedirectUrl={buildLocationChangeRedirectUrl}
          />

          {/* Profile Avatar */}
          <>
            <img
              alt=""
              id="avatar-popover"
              className="nav-avatar"
              data-testid="nav-header-nav-avatar"
              width="30"
              height="30"
              src={require('../../assets/icons/avatar-dots.svg').default}
            />
            <UncontrolledTooltip
              data-testid="nav-header-app-profile-uncontrolled-tooltip"
              placement="bottom-end"
              target="avatar-popover"
            >
              {I18n.t('APP_PROFILE_TOOLTIP')}
            </UncontrolledTooltip>
            <UncontrolledPopover
              data-testid="nav-header-avatar-uncontrolled-popover"
              placement="bottom-end"
              trigger="legacy"
              isOpen={avatarPopupOpen}
              target="avatar-popover"
              toggle={toggleAvatarPopover}
            >
              <Card
                className="nav-avatar-menu"
                data-testid="nav-header-nav-avatar-menu"
              >
                <CardBody data-testid="nav-header-nav-avatar-card-body">
                  <h3
                    className="nav-avatar-name"
                    data-testid="nav-header-nav-avatar-name"
                  >
                    {userToken.name}
                  </h3>
                  <h4
                    className="nav-avatar-email"
                    data-testid="nav-header-nav-avatar-email"
                  >
                    {userToken.email}
                  </h4>
                  {selectedLocation && (
                    <>
                      <DropdownItem
                        data-testid="nav-header-selected-location-dropdown-item"
                        divider
                      />
                      <div
                        data-testid="nav-header-navigate-to-landing"
                        onClick={navigateToLanding}
                      >
                        <h3
                          className="nav-admin-link"
                          data-testid="nav-header-selected-location-nav-link"
                        >
                          {I18n.t('APP_MENU_LANDING')}
                        </h3>
                      </div>
                    </>
                  )}
                  {authState?.accessToken &&
                    claimsAdminRole(authState.accessToken.claims) && (
                      <>
                        <DropdownItem
                          data-testid="nav-header-admin-dropdown-item"
                          divider
                        />
                        <div
                          onClick={navigateToAdmin}
                          data-testid="nav-header-navigate-to-admin"
                        >
                          <h3
                            className="nav-admin-link"
                            data-testid="nav-header-nav-admin-link"
                          >
                            {I18n.t('APP_MENU_ADMIN')}
                          </h3>
                        </div>
                      </>
                    )}
                  <DropdownItem divider />
                  {/* Language toggle */}
                  {!loading ? (
                    <>
                      <h3
                        className="language-selection-heading"
                        data-testid="nav-header-language-selection-heading"
                      >
                        {I18n.t('LANGUAGE_SELECTION_HEADING')}
                      </h3>
                      <Col
                        className="language-selection"
                        data-testid="nav-header-language-selection-col"
                      >
                        <Row data-testid="nav-header-english-language-selection-row">
                          <div
                            className="radio-item"
                            data-testid="nav-header-english-language-selection-radio-item"
                          >
                            <input
                              data-testid="nav-header-english-language-selection-radio-input"
                              type="radio"
                              id="radioItem1"
                              defaultChecked={languageLocale === 'en'}
                              onClick={toggleLanguageLocale}
                            />
                            <label
                              data-testid="nav-header-english-radio-item-language"
                              htmlFor="radioItem1"
                            >
                              {I18n.t('LANGUAGE_ENGLISH')}
                            </label>
                          </div>
                        </Row>
                        <Row>
                          <div
                            className="radio-item"
                            data-testid="nav-header-spanish-language-selection-row"
                          >
                            <input
                              data-testid="nav-header-spanish-lanaguage-selection-radio-input"
                              type="radio"
                              id="radioItem2"
                              defaultChecked={languageLocale === 'es'}
                              onClick={toggleLanguageLocale}
                            />
                            <label
                              data-testid="nav-header-spanish-radio-item-language"
                              htmlFor="radioItem2"
                            >
                              {I18n.t('LANGUAGE_SPANISH')}
                            </label>
                          </div>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <h3
                      className="loading-translation"
                      data-testid="nav-header-loading-translation"
                    >
                      {' '}
                      {I18n.t('LANGUAGE_LOADING')}
                    </h3>
                  )}
                  <DropdownItem
                    data-testid="nav-header-sign-out-dropdown-item"
                    divider
                  />
                  <div
                    className="sign-out-link"
                    data-testid="nav-header-sign-out-link"
                    onClick={signOut}
                  >
                    {I18n.t('APP_MENU_SIGN_OUT')}
                  </div>
                </CardBody>
              </Card>
            </UncontrolledPopover>
          </>
        </Nav>
      )}
    </Navbar>
  );
};

export default NavHeader;
