import { createContext, useCallback, useMemo } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import {
  Route,
  Switch,
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import {
  Breadcrumbs,
  Button,
  Col,
  Container,
  Row,
  TabItem,
  Tabs,
  Typography,
} from 'cfa-react-components';
import NavHeader from '../../NavHeader/NavHeader';
import './TabLayout.scss';
import BetaBadge from '../../common/BetaBadge/BetaBadge';
import { routeToNewApp } from '../../../utils/redirectUtil';

export const SelectedTabContext = createContext({
  tab: null,
  changeTab: () => {},
});

const TabLayout = ({
  title,
  baseUrl,
  content,
  disabled,
  showBetaBadge = false,
  isAdminPath = false,
  buttonProps = null,
  homeUrl = '/',
}) => {
  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const { locationNumber } = useParams();

  /*
   * Utility function to help build out the value that should be passed to the `path` attribute of the
   * react-router `<Path />` component.
   */
  const buildRoutePath = (tab) => {
    // initialize the route path for the base path of the tab.
    let routePath = tab.basePathBuilder(path);

    // if the tab has an optional path param defined, update the
    // route patch to include that as an optional path param.
    if (tab.optPathParam) {
      routePath += `/:${tab.optPathParam}?`;
    }

    // return the route path
    return routePath;
  };

  const selectedTab = useMemo(() => {
    const isExactUrlMatch = (tab) => {
      return [
        tab.basePathBuilder(url),
        `${tab.basePathBuilder(url)}/`, // account for a trailing slash in the path
      ].includes(location.pathname);
    };

    /*
     * Iterating over the array of key/value pairs of the content object,
     * reduce the array down to the tab key/value pair that is the "closest match" to
     * the current path (`location.pathname`)
     */
    const [matchedTabKey] = Object.entries(content).reduce(
      (previousTabKeyValue, currentTabKeyValue) => {
        const [, previousTab] = previousTabKeyValue;
        const [, currentTab] = currentTabKeyValue;

        // check if the "previous" tab of the reduce operation is an exact path match,
        // and if so...return it
        if (isExactUrlMatch(previousTab)) {
          return previousTabKeyValue;
        }

        // check if the "current" tab of the reduce operation is an exact path match, and if
        // so...return it
        if (isExactUrlMatch(currentTab)) {
          return currentTabKeyValue;
        }

        // at this point, we know that neither tabs of the reduce operations are exact path matches,
        // so now we need to determine which tab of the two is the "closest" match.
        const [previousTabMatch] = location.pathname.match(
          previousTab.basePathBuilder(url),
        ) || [''];
        const [currentTabMatch] = location.pathname.match(
          currentTab.basePathBuilder(url),
        ) || [''];

        // compare the length of the two matches, and return the key/value pair corresponding to the longest
        // match.
        return currentTabMatch.length > previousTabMatch.length
          ? currentTabKeyValue
          : previousTabKeyValue;
      },
    );

    return matchedTabKey;
  }, [content, location.pathname, url]);

  const onTabChanged = useCallback(
    (tabId) => {
      history.push(content[tabId].basePathBuilder(url));
    },
    [content, history, url],
  );

  const contextValue = useMemo(
    () => [selectedTab, onTabChanged],
    [selectedTab, onTabChanged],
  );

  return (
    <SelectedTabContext.Provider
      data-testid="tab-layout-selected-tab-contetxt-provider"
      value={contextValue}
    >
      <NavHeader
        data-testid="tab-layout-nav-header"
        buildLocationChangeRedirectUrl={
          isAdminPath ? null : (locNum) => `/${locNum}${baseUrl}`
        }
      />
      <Container data-testid="tab-layout-container">
        <Row data-testid="tab-layout-home-title-row">
          <Col data-testid="tab-layout-home-title-col">
            <Breadcrumbs
              breadcrumbs={[
                {
                  label: 'Home',
                  onClick: () =>
                    isAdminPath
                      ? routeToNewApp(`/admin/landing`)
                      : routeToNewApp(`/${locationNumber}/landing`),
                },
                {
                  label: title,
                },
              ]}
              className="tab-layout__breadcrumbs"
            />

            <div className="tab-layout__title-container">
              <Typography
                data-testid="tab-layout-title"
                variant="h1"
                gutterBottom
              >
                {title}
              </Typography>
              {showBetaBadge && <BetaBadge />}
            </div>
          </Col>
        </Row>

        <Row data-testid="tab-layout-tabs-row">
          <Col data-testid="tab-layout-tabs-col">
            <div className="tab-layout__tabs-container">
              <Tabs
                value={selectedTab}
                activeItemKey={selectedTab}
                onChange={onTabChanged}
                color="secondary"
                className="tab-layout__tabs-list"
                data-testid="tab-layout-tabs-list"
              >
                {Object.entries(content).map(([key, item]) => (
                  <TabItem
                    itemKey={key}
                    key={key}
                    index={key}
                    disabled={item.disabled ? item.disabled : disabled}
                    className="tab-layout__tab-item"
                    data-testid="tab-layout-tab-item"
                  >
                    {item.label}
                  </TabItem>
                ))}
              </Tabs>
              {buttonProps && buttonProps.showOnTab.includes(selectedTab) && (
                <div className="tab-layout__button-container">
                  <Button
                    size="md"
                    color={buttonProps.color || 'secondary'}
                    variant={buttonProps.variant || 'outlined'}
                    onClick={buttonProps.onClick}
                    data-testid="tab-layout-optional-button"
                  >
                    {buttonProps.text || 'No Text Provided...'}
                  </Button>
                </div>
              )}
            </div>

            <Switch data-testid="tab-layout-switch">
              {Object.entries(content).map(([key, tab]) => (
                <Route
                  data-testid="tab-layout-route"
                  key={key}
                  exact
                  path={buildRoutePath(tab)}
                >
                  <div
                    data-testid="tab-layout-tab-component"
                    style={{ margin: '2rem 0' }}
                  >
                    {tab.component}
                  </div>
                </Route>
              ))}
            </Switch>
          </Col>
        </Row>
      </Container>
    </SelectedTabContext.Provider>
  );
};

export default withOktaAuth(withRouter(TabLayout));
