import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { API_GET } from '../api/API';

const domain = process.env.REACT_APP_SERVER_URL;

const useProgramManagementQuestionIds = (program = 'SMARTSHOP') => {
  const [questionIds, setQuestionIds] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const getQuestionIds = useCallback(async () => {
    try {
      const url = `${domain}/assessment/program-management/question/internal-ids?${queryString.stringify(
        { program },
      )}`;

      const { data } = await API_GET(url);
      return data;
    } catch (error) {
      console.error(error);
    }
  }, [program]);

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      setIsFetching(true);
      const result = await getQuestionIds();
      if (isMounted && result) {
        setQuestionIds(result);
        setIsFetching(false);
      }
    };

    getData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { questionIds, isFetching };
};

export default useProgramManagementQuestionIds;
