import { useEffect } from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import {
  SecureRoute,
  Security,
  LoginCallback,
  useOktaAuth,
} from '@okta/okta-react';

import { toRelativeUrl } from '@okta/okta-auth-js';
import { ReactNotifications } from 'react-notifications-component';

import { Provider, useDispatch } from 'react-redux';

import { store } from './app/store';

import { setUserSession } from './app/actions/AuthActions';

// Load component styles
import 'cfa-react-components/dist/styles.min.css';

import Landing from './app/pages/Landing/Landing';
import Admin from './app/pages/Admin/Admin';
import AmendmentContainer from './app/pages/Admin/Amendments/AmendmentContainer';
import SelfAssessment from './app/pages/Admin/SelfAssessment/SelfAssessment';
import HealthAndHygiene from './app/pages/HealthAndHygiene/HealthAndHygiene';
import SafeAuditAdmin from './app/pages/Admin/SafeAudits/SafeAuditAdmin';
import SafeQuestion from './app/pages/Admin/SafeQuestion/SafeQuestion';
import EmailDeepLinkRedirect from './app/components/EmailDeepLinkRedirect';
import { initLanguageLocale } from './i18n/utils';
import AddSafeQuestion from './app/pages/Admin/SafeQuestion/AddSafeQuestion';
import RequestAmendmentForm from './app/pages/ReportDashboard/RequestAmendmentForm/RequestAmendmentForm';
import AdminAppealForm from './app/pages/Admin/AdminAppealForm/AdminAppealForm';
import { oktaAuthClient, claimsOneRole } from './app/utils/AuthUtils';
import ReportDashboard from './app/pages/ReportDashboard/ReportDashboard';
import LicenseeVisitReportDashboard from './app/pages/Assessment/LicenseeVisitReportDashboard';
import LicenseeVisitReportForm from './app/pages/Assessment/LicenseeVisitReportForm/LicenseeVisitReportForm';
import Profile from './app/pages/Profile/Profile';
import { LVRStoreProvider } from './app/components/Assessment/store/reducers';
import SmartShopDashboard from './app/pages/SmartShop/SmartShopDashboard';
import InquiryForm from './app/pages/SmartShop/InquiryForm/InquiryForm';
import SmartShopAmendments from './app/pages/SmartShopAmendments/SmartShopAmendments';
import AdminAmendmentForm from './app/pages/SmartShopAmendments/AdminAmendmentForm/AdminAmendmentForm';
import { routeToNewApp } from './app/utils/redirectUtil';
// import checkServerUpStatus from './app/utils/checkServerUpStatus';

const withAdminCheck = (Component) => {
  return (props) => <Component {...props} />;
};

const LicenseeVisitReportFormWithAdminCheck = withAdminCheck(() => (
  <LVRStoreProvider>
    <LicenseeVisitReportForm />
  </LVRStoreProvider>
));

const ReportDashboardWithAdminCheck = withAdminCheck(({ location }) => {
  if (location.pathname.includes('inquiry-form')) {
    return <RequestAmendmentForm />;
  }
  return <ReportDashboard />;
});

const SmartShopDashboardChecks = withAdminCheck(() => routeToNewApp());

const SmartShopAmendmentsChecks = ({ location }) =>
  location.pathname.includes('admin-amendment-form') ? (
    <AdminAmendmentForm />
  ) : (
    <SmartShopAmendments />
  );

const App = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();

  // ON App mount
  useEffect(() => {
    initLanguageLocale();
  }, []);

  useEffect(() => {
    // let idTokenInterval;

    if (!authState) {
      // if authState isn't yet defined there is no action we can take so go ahead and early return.

      return;
    }

    // at this point (due to the early return), we can work under the
    // assumption that authState represents a complete state
    // if (authState && !authState.isAuthenticated) {
    //   // if the user isn't authenticated, go ahead initiate a new okta login.
    //   oktaAuth.signInWithRedirect();
    //   routeToNewApp();
    // } else {
    // we know the user is authenticated

    // create an interval that checks the validity of the token every
    // three seconds.
    const idTokenInterval = setInterval(async () => {
      // const isServerUp = await checkServerUpStatus();
      // if (!isServerUp) return;

      const { idToken } = await oktaAuth.tokenManager.getTokens();
      if (!idToken || oktaAuth.tokenManager.hasExpired(idToken)) {
        oktaAuth.tokenManager.clear();
        // oktaAuth.signInWithRedirect();
        routeToNewApp();
      }
    }, 3000);

    if (authState.accessToken && authState.idToken) {
      // if we have an accessToken and idToken, dispatch the action to
      // handle those
      dispatch(setUserSession(authState.accessToken, authState.idToken));
    }
    // }
    return () => {
      if (idTokenInterval) {
        clearInterval(idTokenInterval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return (
    <>
      <Route path="/implicit/callback" component={LoginCallback} />
      <ReactNotifications />
      <SecureRoute path="/" exact component={Landing} />
      <SecureRoute
        path="/report-dashboard"
        exact
        component={withAdminCheck(EmailDeepLinkRedirect)}
      />

      <SecureRoute path="/:locationNumber/landing" exact component={Landing} />
      <SecureRoute path="/:locationNumber/profile" exact component={Profile} />

      {/*
       * this particular path of `/${locationNumber}/report-dashboard/${round}` is used to deeplink to a report
       * from notifications that get sent out after audit ingestion, so any changes to this particular path MUST
       * be accounted for elsewhere (including outside of the React application) at the risk of breaking notification
       * deep-linking integrations
       */}

      <SecureRoute
        path="/:locationNumber/report-dashboard/:round?"
        component={ReportDashboardWithAdminCheck}
      />

      <SecureRoute path="/admin" exact component={Admin} />
      <SecureRoute path="/admin/appeals" exact component={AmendmentContainer} />
      <SecureRoute
        path="/admin/appeals/appeal-form"
        exact
        component={AdminAppealForm}
      />
      <SecureRoute
        path="/admin/self-assessment"
        exact
        component={SelfAssessment}
      />
      <SecureRoute
        path="/admin/safe-question"
        exact
        component={withAdminCheck(SafeQuestion)}
      />
      <SecureRoute
        path="/admin/safe-question/:safeReportId"
        exact
        component={withAdminCheck(AddSafeQuestion)}
      />
      <SecureRoute path="/admin/safe-audit" exact component={SafeAuditAdmin} />
      <SecureRoute
        path={[
          '/admin/smart-shop-amendments/:assessmentId(\\d+)?',
          '/admin/smart-shop-amendments/historical-appeals/:assessmentId?',
        ]}
        component={SmartShopAmendmentsChecks}
      />
      <SecureRoute
        path="/:locationNumber/health-and-hygiene"
        exact
        component={withAdminCheck(HealthAndHygiene)}
      />
      <SecureRoute
        path="/:locationNumber/assessment/:assessmentId?"
        component={withAdminCheck(LicenseeVisitReportDashboard)}
      />
      <SecureRoute
        path="/:locationNumber/licensee-visit-report-form"
        exact
        component={LicenseeVisitReportFormWithAdminCheck}
      />
      <SecureRoute
        path="/:locationNumber/smart-shop/:assessmentId?"
        component={SmartShopDashboardChecks}
      />
    </>
  );
};

const AppWithSecurityAccess = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history?.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuthClient} restoreOriginalUri={restoreOriginalUri}>
      <App />
    </Security>
  );
};

const AppWithRouterAccess = () => (
  <Provider store={store}>
    <Router>
      <AppWithSecurityAccess />
    </Router>
  </Provider>
);
export default AppWithRouterAccess;
