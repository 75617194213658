import queryString from 'query-string';
import { API_GET, API_POST } from '../../../utils';

const domain = process.env.REACT_APP_SERVER_URL;

export const fetchAssessmentResponses = async (
  internalId,
  filterStartDate,
  filterEndDate,
  program = 'SMARTSHOP',
  provider = 'ECOSURE',
  complianceStatus = 'NONCOMPLIANT',
  page = 1,
  pageSize = 10000,
) => {
  const fetchResponses = `${domain}/assessment/response?${queryString.stringify(
    {
      page,
      pageSize,
      program,
      provider,
      complianceStatus,
      internalId,
      filterStartDate,
      filterEndDate,
    },
  )}`;
  const { data: assessmentResponses = [] } = await API_GET(fetchResponses);
  return assessmentResponses;
};

export const amendResponses = async (
  isTargetAllLocations = false,
  name = '',
  effectiveEndDate = '',
  effectiveStartDate = '',
  questionInternalId = '',
  reasonForRequest = '',
  locationNumbers = [],
  amendmentType = 'REVOCATION',
) => {
  const payload = {
    name,
    program: 'SMARTSHOP',
    effectiveEndDate,
    effectiveStartDate,
    questionInternalId,
    reasonForRequest,
    amendmentType,
    locationNumbers,
    isTargetAllLocations,
  };

  const path = `${domain}/assessment/amendments/waiver`;
  return API_POST(path, payload);
};

export const findResponseIds = (stores = [], assessmentResponses = []) => {
  const locationNumbers = stores.map((store) => store.locationNumber);

  const filteredResponses = assessmentResponses.filter((response) =>
    locationNumbers.includes(response.locationNumber),
  );

  return filteredResponses.map((response) => response.id);
};
