import { useEffect } from 'react';
import intersection from 'lodash/intersection';
import { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import { routeToNewApp } from './redirectUtil';

/**
 * Make available a "singleton" of a configured OktaAuth client
 * (so we don't have to manually configured `issuer`, `clientId`,
 * etc. in multiple places)
 */
export const oktaAuthClient = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
});

/**
 * Checks if the given `cfaTokenClaims` claims at least one of the given
 * `wasRoles`. This checks the `cfa_perms` claim of the token
 * @param {*} cfaTokenClaims access token claims of the current user session
 * @param {*} wasRoles array of WAS roles to compare with the token claim roles
 * @returns true if the given token claims at least one of the given `wasRoles`
 */

export const claimsOneRole = (cfaTokenClaims, wasRoles) => {
  const isDevTeam = restOpsWebEcosystemPerms(cfaTokenClaims, ['DEV_TEAM']);
  return (
    isDevTeam ||
    intersection(
      Object.keys(cfaTokenClaims?.cfa_perms?.FOOD_SAFETY_WEB_APP || {}),
      wasRoles,
    ).length > 0 ||
    restOpsWebEcosystemPerms(cfaTokenClaims, wasRoles)
  );
};

export const restOpsWebEcosystemPerms = (cfaTokenClaims, wasRoles) => {
  return (
    intersection(
      Object.keys(
        cfaTokenClaims?.cfa_perms?.RESTAURANT_OPS_WEB_ECOSYSTEM || {},
      ),
      wasRoles,
    ).length > 0
  );
};

/**
 * Verifies if the given token claims claim at least one of the "admin" WAS roles
 * @param {*} cfaTokenClaims - access token claims of the current user session
 * @returns true if the given token claims at least one of the given admin roles.
 */
export const claimsAdminRole = (cfaTokenClaims) => {
  return claimsOneRole(cfaTokenClaims, [
    'SA_ADMIN',
    'PROGRAM_ADMIN_LVR',
    'PROGRAM_ADMIN_SMTSHP',
    'PROGRAM_ADMIN_SAFE',
  ]);
};

export const claimsLicenseeLocationRole = (cfaTokenClaims) => {
  return claimsOneRole(cfaTokenClaims, ['LICENSEE_LOGIN']);
};

export const claimsLicenseeLeadRole = (cfaTokenClaims) => {
  return claimsOneRole(cfaTokenClaims, ['LICENSEE_LEAD']);
};

export const canViewOperatorConsultantContent = (cfaTokenClaims) => {
  const isLicenseeAudience = cfaTokenClaims?.cfa_aud === 'LICENSEE_AUDIENCE';
  return claimsOneRole(cfaTokenClaims, ['PROGRAM_LVR']) && !isLicenseeAudience;
};

export const useIsDevTeam = () => {
  const { authState } = useOktaAuth();
  return restOpsWebEcosystemPerms(authState?.accessToken?.claims, ['DEV_TEAM']);
};

export const useRequireAdmin = () => {
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (!claimsAdminRole(authState.accessToken.claims)) {
      routeToNewApp();
    }
  }, [authState]);
};
