import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import {
  Breadcrumbs,
  Button,
  Checkbox,
  DatePicker,
  Multiselect,
  SearchDropdown,
  TextField,
  Typography,
  useMediaQuery,
} from 'cfa-react-components';
import NavHeader from '../../../components/NavHeader/NavHeader';
import I18n from '../../../../i18n/utils';
import './AdminAmendmentForm.scss';

import {
  isWhitespaceString,
  pushNotification,
} from '../../SmartShop/InquiryForm/utils';
import { amendResponses } from './api';
import { noApiFilter } from '../../../utils/filters';
import { useRequireAdmin } from '../../../utils/AuthUtils';
import { routeToNewApp } from '../../../utils/redirectUtil';
import useProgramManagementQuestionIds from '../../../utils/hooks/useProgramManagementQuestionIds';

const renderOption = (finding) => ({
  id: finding,
  textValue: finding,
  rendered: finding,
});

const isNumericOnly = (v) => /^\d/.test(v);

const splitVersion = (v) =>
  v.split(/[\s.-]+/).map((part) => (Number.isNaN(part) ? part : Number(part)));

const sortIds = (ids = []) =>
  ids.sort((a, b) => {
    const aNumeric = isNumericOnly(a);
    const bNumeric = isNumericOnly(b);

    if (aNumeric && !bNumeric) return -1;
    if (!aNumeric && bNumeric) return 1;

    const aParts = splitVersion(a);
    const bParts = splitVersion(b);
    const maxLength = Math.max(aParts.length, bParts.length);

    for (let i = 0; i < maxLength; i++) {
      if (!aParts[i] || aParts[i] < bParts[i]) return -1;
      if (!bParts[i] || aParts[i] > bParts[i]) return 1;
    }
    return 0;
  });

const AdminAmendmentForm = () => {
  const { authState } = useOktaAuth();
  const { claims } = authState.idToken;
  const history = useHistory();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [adminComment, setAdminComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);
  const [relevantStores, setRelevantStores] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const { questionIds, isFetching } = useProgramManagementQuestionIds();

  const availableLocations = useSelector(
    (state) => state.location.availableLocations,
  );
  const isMobile = useMediaQuery('(min-width:800px)');
  useRequireAdmin();

  const breadcrumbs = [
    {
      label: I18n.t('APP_REPORT_DASHBOARD_BACK_TO_HOME'),
      onClick: () => routeToNewApp(`/admin/landing`),
    },
    {
      label: I18n.t('APP_ADMIN_AMENDMENTS_SMART_SHOP'),
    },
  ];

  const startDateOnChange = (date) => {
    date.setHours(0, 0, 0, 0);
    setStartDate(date);
    if (date > endDate) {
      setEndDate(null);
    }
  };

  const disablePastDates = (current) =>
    startDate ? current < startDate : false;

  const onSearch = (query) =>
    noApiFilter(sortIds(questionIds), (q) =>
      q.toUpperCase().includes(query.toUpperCase()),
    );

  const onChange = async (newQ = null) => {
    if (!newQ) {
      setSelectedQuestionId(null);
      return;
    }
    const searchedFinding = questionIds.find((q) => q === newQ);

    setRelevantStores(availableLocations);
    setSelectedQuestionId(searchedFinding);
  };

  const onSubmit = async () => {
    if (isWhitespaceString(adminComment)) {
      setErrorText(I18n.t('APP_REASON_TEXT_ERROR'));
      return;
    }
    try {
      setIsSubmitting(true);
      await amendResponses(
        selectAll,
        claims.name,
        startDate,
        endDate,
        selectedQuestionId,
        adminComment,
        selectedStores.map((s) => s.locationNumber),
      );
      pushNotification(I18n.t('APP_ADMIN_AMENDMENT_SUCCESS'), false, isMobile);
      history.push(`/admin/smart-shop-amendments`);
    } catch (error) {
      console.error('smart shop admin bulk amendment request: ', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <NavHeader />
      <div className="admin-amendment-form__container">
        <Breadcrumbs
          data-testid="admin-amendment-form-breadcrumbs"
          className="admin-amendment-form__breadcrumbs"
          breadcrumbs={breadcrumbs}
        />
        <div className="admin-amendment-form__body-container">
          <Typography gutterBottom variant="h2">
            {I18n.t('APP_ADMIN_AMENDMENT_FORM')}
          </Typography>
          <Typography gutterBottom>
            {I18n.t('APP_ADMIN_AMMENDMENT_FORM_DESCRIPTION')}
          </Typography>
          <div>
            <DatePicker
              required
              margin
              locale="en-US"
              value={startDate}
              label={I18n.t('APP_START_DATE')}
              onChange={startDateOnChange}
              data-testid="start-date-picker"
            />
            <DatePicker
              required
              margin
              locale="en-US"
              value={endDate}
              onChange={setEndDate}
              label={I18n.t('APP_END_DATE')}
              disabledDates={disablePastDates}
              data-testid="end-date-picker"
            />
          </div>
          <div className="admin-amendment-form__multiselect-container">
            <SearchDropdown
              required
              fullWidth
              onSearch={onSearch}
              value={selectedQuestionId}
              renderOption={renderOption}
              label={I18n.t('APP_CHOOSE_FINDING')}
              disabled={isFetching || !(startDate && endDate)}
              helperText={isFetching ? I18n.t('APP_LOADING_FINDINGS') : ''}
              onChange={onChange}
            />

            <Multiselect
              required
              fullWidth
              value={selectedStores}
              options={relevantStores || []}
              label={I18n.t('APP_CHOOSE_STORES')}
              getOptionId={(opt) => opt.locationNumber}
              disabled={isFetching || !selectedQuestionId || selectAll}
              data-testid="admin-amendment-form-stores-multiselect"
              onChange={(newSelections) => setSelectedStores(newSelections)}
              getOptionText={(opt) =>
                `${opt.location.locationNumber}: ${opt.location.locationName}` ||
                ''
              }
              className="admin-amendment-form__store-selector"
            />
            <Checkbox
              label="Select All Locations"
              checked={selectAll}
              onChange={() => {
                setSelectedStores([]);
                setSelectAll(!selectAll);
              }}
              disabled={isFetching || !selectedQuestionId}
            />
            <TextField
              required
              multiline
              fullWidth
              rows={5}
              maxLength={700}
              value={adminComment}
              errorText={errorText}
              label={I18n.t('APP_REASON_FOR_CHANGE')}
              placeholder={I18n.t('APP_REASON_PLACEHOLDER')}
              helperText={I18n.t('App_REASON_HELPERTEXT')}
              className="admin-amendment-form__text-field"
              data-testid="admin-amendment-form-text-field"
              onChange={(textValue) => setAdminComment(textValue.target.value)}
              disabled={
                !selectedQuestionId ||
                (selectedStores.length === 0 && !selectAll)
              }
            />
          </div>
          <div className="admin-amendment-form__button-container">
            <Button
              size="lg"
              color="secondary"
              variant="outlined"
              href="/admin/smart-shop-amendments"
              data-testid="admin-amendment-form-cancel-button"
            >
              {I18n.t('APP_NEXT_CANCEL')}
            </Button>
            <Button
              size="lg"
              variant="filled"
              color="secondary"
              onClick={onSubmit}
              disabled={
                !selectedQuestionId ||
                (selectedStores.length === 0 && !selectAll) ||
                adminComment.length === 0 ||
                isSubmitting
              }
              className="admin-amendment-form__next-button"
              data-testid="admin-amendment-form-submit-button"
            >
              {I18n.t('APP_SUBMIT_BUTTON')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAmendmentForm;
