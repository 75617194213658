import PropTypes from 'prop-types';

import { Row } from 'reactstrap';
import './BackArrow.scss';

const newAppDomain = process.env.REACT_APP_NEW_APP_URL;

const BackArrow = ({ title, path = '/' }) => {
  const linkTitle = title || 'Back';

  return (
    <Row className="container" data-testid="back-arrow-container-row">
      <a
        href={`${newAppDomain}${path}`}
        className="desktop-back-container"
        data-testid="back-arrow-desktop-back-container"
      >
        <img
          alt=""
          src={require('../../../assets/icons/left-arrow.svg').default}
          height={14}
          width={14}
          className="desktop-back-arrow"
          data-testid="back-arrow-desktop-back-arrow"
        />
        <span
          className="desktop-back-link"
          data-testid="back-arrow-desktop-back-link"
        >
          {linkTitle}
        </span>
      </a>
    </Row>
  );
};

BackArrow.propTypes = {
  title: PropTypes.string.isRequired,
};

export { BackArrow };
