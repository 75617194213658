import { useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Button,
  CardActions,
  Divider,
  Link,
} from 'cfa-react-components';
import I18n from '../../../../../i18n/utils';

const NeedHelpCard = () => {
  return (
    <Card className="need-help-card">
      <CardContent className="need-help-card-content">
        <Typography align="left" variant="h4">
          {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_NEED')}
        </Typography>
        <Typography align="left" variant="caption1">
          {I18n.t(
            'APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_NEED_CAPTION',
          )}
        </Typography>
      </CardContent>
      <CardActions
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Button
          data-testid="need-help-ask-question-button"
          as="a"
          href="https://cfaprod.service-now.com/cfahelp?id=sc_cat_item&sys_id=f0d4679ac31a0a50f77a30ff05013129&sysparm_category=177536741be5a090208599ba234bcbd1"
          target="_blank"
          color="secondary"
          size="md"
          variant="outlined"
        >
          {I18n.t('APP_REPORT_DASHBOARD_NEED_HELP_GENERAL_QUESTION')}
        </Button>
      </CardActions>
      <CardContent className="need-help-card-content">
        <Typography align="left" variant="h4">
          {I18n.t('APP_REPORT_DASHBOARD_NEED_HELP_BEST_PRACTICE_TOOLKIT_TITLE')}
        </Typography>
        <Typography align="left" variant="caption1">
          {I18n.t(
            'APP_REPORT_DASHBOARD_NEED_HELP_BEST_PRACTICE_TOOLKIT_DESCRIPTION',
          )}
        </Typography>
        <Link
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '1rem',
          }}
          href="https://portal.cfahome.com/en/food-and-delivery/food-safety-and-quality/food-safety-best-practices-toolkit/"
          size="body2"
          target="_blank"
          variant="standalone"
        >
          {I18n.t(
            'APP_REPORT_DASHBOARD_NEED_HELP_BEST_PRACTICE_TOOLKIT_LINK_CAPTION',
          )}
        </Link>
      </CardContent>
      <Divider
        orientation="horizontal"
        variant="middle"
        style={{ marginBottom: '1rem', marginTop: '1rem' }}
      />
      <CardContent>
        <Typography gutterBottom align="left" variant="h4">
          {I18n.t('APP_REPORT_DASHBOARD_NEED_HELP_REQUEST_ECOSURE')}
        </Typography>
        <Link
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '1rem',
          }}
          href="https://forms.office.com/pages/responsepage.aspx?id=ElHrwUZ5nUy8V0AEDP46kejN85qPfD5MvtKylBuF20RURUZVSlEzNDJKM1RLTzNFNldYME01OFJUWi4u"
          size="body2"
          target="_blank"
          variant="standalone"
        >
          {I18n.t('APP_REPORT_DASHBOARD_NEED_HELP_ANNUAL_CTE')}
        </Link>
        <Link
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '2rem',
          }}
          href="https://forms.office.com/pages/responsepage.aspx?id=ElHrwUZ5nUy8V0AEDP46kejN85qPfD5MvtKylBuF20RURVk3WlVUUzQyNEI3NEdGMzFOWUhCRERRWC4u"
          size="body2"
          target="_blank"
          variant="standalone"
        >
          {I18n.t('APP_REPORT_DASHBOARD_NEED_HELP_ADDITIONAL_SAFE_CTE')}
        </Link>
      </CardContent>
    </Card>
  );
};

export default NeedHelpCard;
