/* eslint-disable */
// TODO: review disabled eslint rules
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { Multiselect } from 'cfa-react-components';
import {
  Card,
  Button,
  Col,
  Row,
  InputGroup,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import NavHeader from '../../../components/NavHeader/NavHeader';
import {
  getStoreLocations,
  sendSelfAssessmentChainPrompt,
  sendMultipleSelfAssessmentPrompts,
  clearAssessmentPrompt,
} from '../../../actions';
import CFASpinner from '../../../components/Spinner/CFASpinner';
import { getDateToday } from '../../../utils';
import { claimsOneRole } from '../../../utils/AuthUtils';
import './SelfAssessment.scss';
import { routeToNewApp } from '../../../utils/redirectUtil';

class SelfAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: props.loading,
      availableLocations: props.availableLocations,
      form: {
        selectedLocations: [],
        date: getDateToday(),
        type: 'OTHER',
        details: '',
      },
      error: null,
      message: null,
      options: [],
    };

    this.submitDisabled = this.submitDisabled.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate() {
    const locations = this.props.availableLocations;
    const zeroOptions = this.state.options.length === 0;
    if (locations && locations.length > 0 && zeroOptions) {
      this.setState({
        options: this.mapValuesForMultiSelect(locations),
      });
    }
  }

  componentDidMount() {
    if (!claimsOneRole(this.props.authState.accessToken.claims, ['SA_ADMIN']))
      routeToNewApp();
    // Reset Admin Redux state so message or error doesn't persist thru page refreshes
    this.props.clearAssessmentPrompt();
    // Fetch store locations for Typeahead component
    this.props.getStoreLocations();
  }

  onChange(selectedLocation) {
    this.setState({ form: { ...this.state.form, selectedLocation } });
  }

  static getDerivedStateFromProps(props, state) {
    const update = {};

    // Update All Locations
    if (
      props?.availableLocations?.length !== state?.availableLocations?.length
    ) {
      update.availableLocations = props.availableLocations;
    }

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    // Update Error
    if (props.error !== state.error) {
      update.error = props.error;
    }

    // Update Message
    if (props.message !== state.message) {
      update.message = props.message;
    }

    return update;
  }

  handleChangeInput = (event) => {
    if (event == null) return;
    const { target } = event;
    // Handle Location object, which doesn't have a name
    const name = target != null ? target.name : 'selectedLocations';
    // Parse value from event. If event.target is undefined, set value to event (for location)
    const value = event.target != null ? event.target.value : event;
    // Get a snapshot of the current form state
    const currentForm = this.state.form;
    // Update individual value
    const form = {
      ...currentForm,
      [name]: value,
    };

    // Update state
    this.setState({ form });
  };

  mapValuesForMultiSelect(locationsList = []) {
    // Format Data for react-multi-select component library
    const newList = [];
    locationsList.forEach((loc, idx) => {
      newList.push({
        id: idx,
        label: loc.location.locationNumber,
      });
    });
    // Limit locations to 100
    return newList;
  }

  submitDisabled() {
    let bool = false;
    const { selectedLocations, date, type, details } = this.state.form;

    if (
      !selectedLocations.length ||
      (selectedLocations.length > 10 &&
        selectedLocations.length !== this.state.options.length) ||
      date === '' ||
      null ||
      type === '' ||
      null ||
      details === '' ||
      null
    ) {
      bool = true;
    }

    return bool;
  }

  submitForm() {
    const { form } = this.state;
    // Hard code type value for now
    form.type = 'OTHER';
    // If all locations are selected, send one POST
    if (form.selectedLocations.length === this.state.options.length) {
      this.props.sendSelfAssessmentChainPrompt(form);
    } else {
      this.props.sendMultipleSelfAssessmentPrompts(form);
    }
  }

  render() {
    return (
      <>
        <NavHeader />
        <Col className="SelfAssessment container ">
          <Row className="container">
            {/* TODO: FOOD-268 Anchor tag generating a warning. Recommend changing to BackArrow component */}
            <a
              onClick={this.props.history.goBack}
              className="desktop-back-container"
            >
              <img
                alt=""
                src={require('../../../assets/icons/left-arrow.svg').default}
                height={15}
                width={15}
                className="desktop-back-arrow"
              />
              <span className="desktop-back-link">Admin Dashboard</span>
            </a>
          </Row>
          <Row>
            <Col className="container">
              <Card className="self-assessment-card container">
                {!this.state.message ? (
                  <>
                    <FormGroup className="self-assessment-form">
                      <Row className="container">
                        <Col className="assess-title-container">
                          <img
                            alt=""
                            src={
                              require('../../../assets/icons/assess.svg')
                                .default
                            }
                            className="assess-title-icon"
                          />
                          <h2 className="assess-title">
                            {' '}
                            Self Assessment Streak Update Form
                          </h2>
                        </Col>
                      </Row>
                      <Row className="input-row cfa-multiselect-height">
                        {/* Store Number */}
                        <Label className="input-label">Store Number</Label>
                        <InputGroup>
                          {this.state.form &&
                            this.state.options &&
                            this.state.options.length && (
                              <div className="multi-select-container">
                                <Multiselect
                                  options={this.state.options}
                                  showCheckboxes
                                  getOptionId={(opt) => opt.id}
                                  getOptionText={(opt) => opt.label}
                                  value={this.state.form.selectedLocations}
                                  onChange={this.handleChangeInput}
                                  placeholder="Search"
                                />
                              </div>
                            )}
                        </InputGroup>
                        {this.state.form.selectedLocations.length > 10 &&
                          this.state.form.selectedLocations.length !==
                            this.state.options.length && (
                            <h4 className="selection-error">
                              Please select no more than 10 locations.
                              Alternatively, choose the 'Select All' option to
                              update all locations at once.
                            </h4>
                          )}
                      </Row>
                      {/* Date */}
                      <Row className="input-row">
                        <Label className="input-label">Date</Label>
                        <InputGroup>
                          <Input
                            placeholder="Please input date in format of M/d/yyyy"
                            name="date"
                            type="date"
                            value={this.state.form.date}
                            onChange={this.handleChangeInput}
                          />
                        </InputGroup>
                      </Row>
                      {/* Type */}

                      {/* Commented out for now, hardcoding 'OTHER' as type on this.state.form */}

                      {/* <Row className="input-row">
                        <Label className="input-label">Type</Label>
                        <InputGroup>
                          <Input
                            type="select"
                            id="select"
                            name="type"
                            value={this.state.form.type}
                            onChange={this.handleChangeInput}>
                            <option>WEATHER</option>
                            <option>FOOD SAFETY</option>
                            <option>OTHER</option>
                          </Input>
                        </InputGroup>
                      </Row> */}
                      {/* Details */}
                      <Row className="input-row">
                        <Label className="input-label">Details</Label>
                        <InputGroup>
                          <Input
                            placeholder="Enter your answer"
                            id="details"
                            name="details"
                            type="text"
                            maxLength="55"
                            onChange={this.handleChangeInput}
                          />
                        </InputGroup>
                      </Row>
                    </FormGroup>
                    {!this.state.loading ? (
                      <>
                        {this.state.error && (
                          <h4 className="error">
                            There was an error while submitting the form. Please
                            try again.
                          </h4>
                        )}
                        <Button
                          disabled={this.submitDisabled()}
                          className="submit-button"
                          block
                          onClick={this.submitForm}
                        >
                          Submit
                        </Button>
                      </>
                    ) : (
                      <CFASpinner />
                    )}
                  </>
                ) : (
                  <Row className="container">
                    <Col className="success-container">
                      <img
                        alt=""
                        src={
                          require('../../../assets/icons/success-icon.svg')
                            .default
                        }
                        className="success-icon"
                      />
                      <h4>Submission Success!</h4>
                      <Button
                        className="go-back-button"
                        block
                        onClick={this.props.history.goBack}
                      >
                        Go to Admin Dashboard
                      </Button>
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, message } = state.admin;
  const { availableLocations = [], selectedLocation } = state.location;
  // Custom location_number prop if selected location is null
  const location_number =
    selectedLocation != null ? selectedLocation.locationNumber : [];
  return { loading, error, message, availableLocations, location_number };
};

export default withOktaAuth(
  withRouter(
    connect(mapStateToProps, {
      getStoreLocations,
      sendSelfAssessmentChainPrompt,
      sendMultipleSelfAssessmentPrompts,
      clearAssessmentPrompt,
    })(SelfAssessment),
  ),
);
